<script>
export default {
    data() {
            return {
              fullPage: false,
              loader: '',
                
            }
            
        },
    methods: {
        Loader(){
            this.loader = this.$loading.show({
                  // Optional parameters
                  container: this.fullPage ? null : this.$refs.formContainer,
                  loader : 'dots',
                  color: '#6777ef'

                });
          },
    },
}
</script>